// Display clock

(function (window, undefined) {

    function showTime() {
        clock = document.getElementById("clock");
        if (clock) {
            var date = new Date();
            var h = date.getHours();
            var m = date.getMinutes();
            h = (h < 10) ? "0" + h : h;
            m = (m < 10) ? "0" + m : m;
            clock.innerText = h + ":" + m;
            setTimeout(showTime, 1000);
        }
    }

    window.addEventListener("load", showTime)

})(window);
