// Autocompletion of stop list on /dfi

import Autocomplete from '@trevoreyre/autocomplete-js'

(function (window, undefined) {

    function autocompleteStops() {
        if (document.getElementById('autocomplete')) {
            new Autocomplete('#autocomplete', {
                search: input => {
                    if (input.length < 1) {
                        return []
                    }
                    return points.filter(point => {
                        return point.name.toLowerCase().includes(input.toLowerCase())
                    })
                },
                // display name in result list
                getResultValue: result => result.name,
                autoSelect: true,
                onSubmit: result => {
                    if (result) {
                        // invoke stop page
                        window.location = '/dfi/' + result.id;
                    }
                },
            })
        }
    }

    window.addEventListener('load', function () {
        autocompleteStops()
    });

})(window);