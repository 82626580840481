// Handle animations in WebDFI

let pixelsPerSecond = 300;  // default animation speed
if (document.documentElement.clientWidth) {
    pixelsPerSecond = Math.max(document.documentElement.clientWidth / 6, 100);
    console.log(`Scroll speed set to ${pixelsPerSecond}`);
}
const textDelimiter = '+++'; // delimiter needed to mark the end an alert when scrolling

(function (window, document, undefined) {

    // find all elements marked as scrollable and make them autoscroll if needed
    function scrollTextIfNeeded(dup) {
        let texts = document.getElementsByClassName('scrollable');
        for (let i = 0; i < texts.length; i++) {
            let elem = texts[i];
            //console.debug(elem.offsetWidth, elem.clientWidth, elem.scrollWidth);
            if (elem.parentNode.clientWidth < elem.scrollWidth) {
                if (!elem.classList.contains('scrolling')) {
                    // duplicate content to fake an endless scroll, only on load
                    if (dup) {
                        elem.textContent = shadowText(elem.textContent);
                    }
                    // this triggers the scroll animation
                    elem.classList.add('scrolling');
                }
                // adjust speed according to length
                elem.style.animationDuration = elem.scrollWidth / pixelsPerSecond + "s";
            } else {
                elem.classList.remove('scrolling');
            }
        }
    }

    // invoke scrollTextIfNeeded when a page or part of it is loaded
    window.addEventListener('load', function () {
        scrollTextIfNeeded(true)
    });
    window.addEventListener('htmx:load', function () {
        scrollTextIfNeeded(true)
    });
    // when window is resized, do not build a shadow copy of the alert text
    window.addEventListener('resize', function () {
        scrollTextIfNeeded(false)
    });

    // shadowText builds a shadow copy of the alert text, needed for endless scrolling
    function shadowText(text) {
        text = text.trim();
        if (text.substring(text.length - textDelimiter.length, text.length) === textDelimiter) {
            return text + ' ' + text + ' ';
        }
        return text + ' ' + textDelimiter + ' ' + text + ' ' + textDelimiter  + ' ';
    }

    module.exports.scrollTextIfNeeded = scrollTextIfNeeded;
})(window, document);
