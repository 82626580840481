// Disable console log on production
if (process.env.NODE_ENV !== 'development') {
    console.log = function () {};
}

// reload standard pages after 120 minutes (this has impact on the number of page views in Plausible)
window.stdPageReloadInterval = 120 * 60 * 1000
// reload offline page after 15 second
window.offLinePageReloadInterval = 15 * 1000
// update service worker after 3 hours in production
let swUpdateInterval = 3 * 60 * 60 * 1000; // 3 hours

import htmx from 'htmx.org';
import {scrollTextIfNeeded} from "./effects";
import Plausible from 'plausible-tracker'
import '../scss/app.scss';
import './clock';
import './autocomplete';
import './fullscreen';
import '@trevoreyre/autocomplete-js/dist/style.css'

const plausible = Plausible({
    domain: 'abfahrten-molli.de',
    trackLocalhost: (process.env.NODE_ENV === 'development'),
})

try {
    plausible.trackPageview();
} catch {
    console.log('CLIENT: plausible.trackPageview() failed.')
}

// ServiceWorker is a progressive technology. Ignore unsupported browsers
if ('serviceWorker' in navigator) {
    if (process.env.NODE_ENV !== 'production') {
        swUpdateInterval = 60 * 1000; // 1 minutes
    }
    console.log(`CLIENT: Service worker update interval set to ${swUpdateInterval / 1000} seconds`);
    navigator.serviceWorker.register('/service-worker.js').then(function (reg) {
        console.log('CLIENT: service worker registration complete.');
        // force refresh of service worker
        setInterval(function () {
            console.log('CLIENT: Requesting service worker update');
            reg.update().catch(function () {
                console.log('CLIENT: Requested service worker update failed');
            })
        }, swUpdateInterval)
    }, function () {
        console.log('CLIENT: service worker registration failure.');
    });
} else {
    console.log('CLIENT: service worker is not supported.');
}

htmx.on("htmx:load", function () {
    scrollTextIfNeeded(true);
});
